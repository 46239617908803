<template>
  <div class="mediaReviewActions">
    <div
      class="p-2 mb-2 mr-2 text-center border rounded"
    >
      <div class="imagePlaceholderWrapper rounded mb-1">
        <div
          v-if="run.installationId"
          class="row pt-3"
          style="justify-content: center"
        >
          <label class="bold mr-2 mb-0">{{ $t('installation') }}:</label>
          <span>{{ run.installationId }}</span>
        </div>
        <div
          class="row pt-5"
          style="justify-content: space-around"
        >
          <button
            v-if="isVideo && !playing"
            class="btn btn-primary btn-sm mt-3"
            @click="play()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="play"
            />
            <span>Play</span>
          </button>
          <button
            v-if="isVideo && playing"
            class="btn btn-primary btn-sm mt-3"
            @click="pause()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="pause"
            />
            <span>Stop</span>
          </button>
          <template v-if="status == null">
            <button
              class="btn btn-primary mt-3 float-left"
              @click="createReport(true)"
            >
              Confirm
            </button>
            <button
              class="btn btn-primary mt-3 float-right"
              @click="createReport(false)"
            >
              Reject
            </button>
          </template>
          <template v-else>
            <p
              :class="status ? 'text-success' : 'text-danger'"
              style="padding-top: 15px;"
            >
              {{ status ? "CONFIRMED" : "REJECTED" }}
            </p>
          </template>
        </div>
      </div>
      <div>
        <label class="bold mr-2 mb-0">{{ '' }}</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MediaReviewActions",
  props: {
    run: {
      type: Object,
      required: true
    },
    isVideo: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data () {
    return {
      playing: false,
      status: null
    }
  },
  methods: {
    getRunIds (run) {
      var runIds = ""; 
      if(run.firstItem) {
        runIds += run.firstItem.id + ",";
      }
      else {
        runIds += ",";
      }
      if(run.secondItem) {
        runIds += run.secondItem.id + ",";
      }
      else {
        runIds += ",";
      }
      if(run.thirdItem) {
        runIds += run.thirdItem.id;
      }
      return runIds;
    },
    createReport (val) {
      this.status = val;
      let report = {
        'spotId': this.run.spotId,
        'installationId': this.run.installationId,
        'reviewResult': val,
        'runIds': this.getRunIds(this.run) 
      }
      this.axios.post('/Media/CreateMediaReviewReport', report)
        .then(() => {
          if(val) {
            this.$snotify.success(this.$t('mediaReview.confirmed'));
          }
          else {
            this.$snotify.success(this.$t('mediaReview.rejected'));
          }
        });
    },
    play () {
      this.playing = true;
      this.$emit("isPlaying", this.index, true);
    },
    pause () {
      this.playing = false;
      this.$emit("isPlaying", this.index, false);
    },
    getImage (run) {
      if (run == null) {
        return;
      }
      return "https://api.skiline.cc/php/app/api-helper/0.10/Admin/Video/decoratedRun.php?runId="+run.id+"&apiKey=383zhdd7yJHDOcvmiq563wnmdjuhJ83wdsn";
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.imagePlaceholderWrapper {
  position: relative;
  width: 350px;
  height: 200px;
}
</style>
